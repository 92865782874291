import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const colors = require('../../colors.json');


const RedirectModal = ({ display }) => {
    return (
        <div>
            {!display ? 
                '' : 
                <div className='modal-container'>
                    <div className={`modal-redirect ${colors.bg_card_background} ${colors.primary}`}>
                        <h1>Redirecting to Stripe...</h1>
                        <FontAwesomeIcon 
                            icon={faCircleNotch}
                            className={`modal-redirect-spinner ${colors.accent}`}
                            spin />
                    </div>
                </div>}
        </div>
    );
};


export default RedirectModal;
