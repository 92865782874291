import React from 'react';

import ValueDisplay from './ValueDisplay';
const text = require('../../textChunks.json');
const colors = require('../../colors.json');


const GettingStartedTutorial = () => {

    const headerColor = colors.primary;

    return (
        <div className='getting-started-tutorial'>
            <p>{text.tutorialIntro}</p>
            <h2 className={headerColor}>{text.tutorialAuthSubHeader}</h2>
            <p>{text.tutorialAuthDetails}</p>
            <h2 className={headerColor}>{text.tutorialConvertSubHeader}</h2>
            <h3>{text.tutorialGetSubSubHeader}</h3>
            <p>{text.tutorialGetDetails}</p>
            <ValueDisplay 
                title={'Example GET Request'} 
                value={'GET ' + text.appDomain + text.apiRoute + '?url=https://www.Amazon.com&min_size=10'} 
                displayCopy={true} stackDisplay={true} />
            <br/>
            <ValueDisplay title={'query params'} value={'url=<The full URL of the website>'} />
            <ValueDisplay title={'query params'} value={'min_size=<Min size of extracted lists and tables>'} />
            <br/>
            <h3>{text.tutorialPostSubSubHeader}</h3>
            <p>{text.tutorialPostDetails}</p>
            <ValueDisplay 
                title={'Example POST Request'} 
                value={'POST ' + text.appDomain + text.apiRoute + '?min_size=10'} 
                displayCopy={true} stackDisplay={true} />
            <br/>
            <ValueDisplay title={'Required header'} value={'Content-Type: text/html'} />
            <ValueDisplay title={'query params'} value={'min_size=<Min size of extracted lists and tables>'} />
            <br/>
            <h2 className={headerColor}>{text.tutorialResponseSubHeader}</h2>
            <p>{text.tutorialResponseDetails}</p>
            <h2 className={headerColor}>{text.tutorialTokensSubHeader}</h2>
            <p>{text.tutorialTokensDetails}</p>
        </div>
    );
};


export default GettingStartedTutorial;
