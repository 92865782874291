import React from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import { firebaseApp } from '../admin';
import logo from '../WebToCsv_icon.svg';
const text = require('../textChunks.json');
const colors = require('../colors.json');


const auth = getAuth(firebaseApp);


const LandingPage = () => {

    const openSignInPopup = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider);
    };

    return (
        <div className={`page-landing ${colors.bg_background} ${colors.primary}`}>
            <div className='landing-content'>
                <div className='landing-description'>
                    <div className='landing-header'>
                        <img src={logo} alt='logo'
                            style={{ width: 75, height: 75 }}/>
                        <h1 className={colors.accent}>{text.appName}</h1>
                    </div>
                    <br/>
                    <h2>{text.landingPageSubHeading}</h2>
                    <p className={colors.secondary}>{text.landingPageDesc}</p>
                    <ul className={colors.secondary}>
                        <li>{text.landingPageBullet1}</li>
                        <li>{text.landingPageBullet2}</li>
                    </ul>
                </div>
                <div className='landing-cta'>
                    <h2>{text.landingPageCTA}</h2>
                    <button 
                        className={`landing-cta-button ${colors.accent} ${colors.border_accent} ${colors.bg_background}`}
                        onClick={openSignInPopup}>
                            <FontAwesomeIcon icon={faGoogle} size='xl' 
                                className={`landing-cta-button-icon ${colors.accent}`} />
                            Sign In
                    </button>
                </div>
            </div>
        </div>
    );
};


export default LandingPage;

