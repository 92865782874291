import React from 'react';

import CopyButton from './CopyButton';
const colors = require('../../colors.json');


const ValueDisplay = ({ title, value, displayCopy, displayWarning, stackDisplay }) => {
    return (
        <div className={stackDisplay ? 'value-disp-stacked' : 'value-disp'}>
            <h3>{title}:</h3>
            <span className={`${colors.primary} ${displayWarning ? colors.border_accent : ''}`}>
                {value}
                {displayCopy ? <CopyButton text={value} /> : ''}
            </span>
        </div>
    );
};


export default ValueDisplay;
