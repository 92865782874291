import { collection, query, where, getDocs, addDoc, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const PRODUCTS_COLLECTION = 'stripe-products';


/**
 * Get all active prducts in the project.
 * 
 * @param {Object} db - The firestore database object for the project 
 * @returns {Array.<Object>} An array of all active products
 */
const getProducts = async (db) => {
    const q = query(
        collection(db, PRODUCTS_COLLECTION), 
        where('active', '==', true)
    );

    const querySnapshot = await getDocs(q);
    const productsPromises = querySnapshot.docs.map(async (productDoc) => {
        let productInfo = productDoc.data();
        // get prices subcollection per product
        const pricesCollection = collection(productDoc.ref, 'prices');
        const priceQuerySnapshot = await getDocs(pricesCollection);

        const priceDoc = priceQuerySnapshot.docs[0];
        productInfo['price'] = priceDoc.id;
        productInfo['priceInfo'] = priceDoc.data();
        return productInfo;
    });

    const products = await Promise.all(productsPromises);
    return products;
};

/**
 * Get the current users active subscription, if they have one
 * 
 * @param {Object} db - The firestore database object for the project 
 * @param {Object} currentUser - currentUser supplied by firebase auth 
 * @returns {Object} A subscription object if the user has an active subscription, or null
 */
const getActiveSubscription = async (db, currentUser) => {
    const q = query(
        collection(db, 'stripe-customers', currentUser.uid, 'subscriptions'), 
        where('status', 'in', ['trialing', 'active'])
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        return null;
    }
    return querySnapshot.docs[0].data();
};

/**
 * Initiates calls to get access to the stripe customer portal. The onComplete callback should redirect to the url.
 * 
 * @param {Object} firebaseApp - The Firebase app object created on firebase initialization 
 * @param {Function(url): void} onComplete - Callback triggered once the session is ready 
 */
const initiateCustomerPortalSession = async (firebaseApp, onComplete) => {
    const functions = getFunctions(firebaseApp, 'us-central1');
    const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    
    createPortalLink({
        returnUrl: window.location.origin
    }).then((result) => {
        onComplete(result.data.url);
    }).catch((error) => {
        console.error('stripeUtil: initiateCustomerPortalSession: Error getting portal URL', error);
    });
};

/**
 * Initiates a Stripe payment session. The onComplete callback should redirect to the payment url.
 * 
 * @param {Object} db - The firestore database object for the project 
 * @param {Boolean} isOneTime - Specifies whether or not this is a one-time payment (if false, is subscription) 
 * @param {Object} currentUser - currentUser supplied by firebase auth 
 * @param {Object} product - the product to start a payment session for 
 * @param {Function(error, url): void} onComplete - callback triggered once the session is ready
 * @returns {void}
 */
const initiatePaymentSession = async (db, isOneTime, currentUser, product, onComplete) => {
    if (!product || Object.values(product).length === 0) {
        console.error('stripeUtil: initiatePaymentSession: Provided product is null or empty');
        return;
    }

    let sessionData = {
        price: product.price, 
        success_url: window.location.origin, 
        cancel_url: window.location.origin
    };

    if (isOneTime) {
        sessionData['mode'] = 'payment';
    }
    
    const sessionRef = await addDoc(
        collection(db, `stripe-customers/${currentUser.uid}/checkout_sessions`), 
        sessionData
    );

    onSnapshot(sessionRef, (snap) => {
        const { error, url } = snap.data();
        onComplete(error, url);
    });
};


export {
    getProducts, 
    getActiveSubscription, 
    initiateCustomerPortalSession, 
    initiatePaymentSession
};
