import React, { useState } from 'react';

import copy from 'clipboard-copy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faCheck } from '@fortawesome/free-solid-svg-icons'

const colors = require('../../colors.json');


const delay = (ms) => new Promise((resolve) => {
    setTimeout(resolve, ms);
});


const CopyButton = ({ text }) => {
    
    // keep track of if the user clicked the button
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        copy(text)
            .catch((error) => {
                console.error('CopyButton: An error occured when copying to clipboard:', error);
                throw error;
            });
        
        setCopied(true);

        delay(2000)
            .then(() => {
                setCopied(false);
            });
    };

    const renderCopyButton = () => {
        return (
            <button 
                className='copy-button'
                onClick={handleCopy}>
                <FontAwesomeIcon 
                    icon={faClone}
                    className={colors.primary}/>
            </button>
        );
    };

    const renderSuccessIcon = () => {
        return (
            <FontAwesomeIcon 
                icon={faCheck}
                className={colors.accent} 
                size='xs'/>
        );
    };
    
    return (  
        <div>
            {copied ? renderSuccessIcon() : renderCopyButton()}
        </div>
    );
};


export default CopyButton;
