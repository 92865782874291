import { initializeApp } from 'firebase/app';
import { getAnalytics} from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyBei95NeqMEvoJ7YqqeUHg3ASEwysZ40o4",
  authDomain: "html-to-csv.firebaseapp.com",
  // authDomain: 'localhost', // local emulator setup
  projectId: "html-to-csv",
  storageBucket: "html-to-csv.appspot.com",
  messagingSenderId: "787730919877",
  appId: "1:787730919877:web:601b24b9e58aa32a895bac",
  measurementId: "G-ZL0VZDX0TE"
};

const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp);

const db = getFirestore(firebaseApp);


export {
  firebaseApp, 
  db
}