import './App.css';

import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import { firebaseApp } from './admin';
import LandingPage from './components/LandingPage';
import AdminPage from './components/AdminPage';




const auth = getAuth(firebaseApp);


function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      {user ? <AdminPage /> : <LandingPage />}
    </div>
  );
}

export default App;
