import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import { firebaseApp, db } from '../admin.js';
import { getProducts, getActiveSubscription, initiateCustomerPortalSession, initiatePaymentSession } from '../util/stripeUtil.js';
import ValueDisplay from './admin/ValueDisplay.js';
import GettingStartedTutorial from './admin/GettingStartedTutorial.js';
import RedirectModal from './admin/RedirectModal.js';
import logo from '../WebToCsv_icon.svg';
const text = require('../textChunks.json');
const colors = require('../colors.json');


const auth = getAuth(firebaseApp);

const SUBSCRIPTION_PROD_NAME = 'unlimited subscription';
const ONE_TIME_PROD_NAME = 'one-time payment'; 


const AdminPage = () => {
    const [apiKey, setApiKey] = useState('');
    const [numTokens, setNumTokens] = useState(0);
    const [subscription, setSubscription] = useState(null);
    const [subProduct, setSubProduct] = useState({});
    const [oneTimeProduct, setOneTimeProduct] = useState({});
    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => {
        // get user data
        getCurrentUserApiKeyData()
            .then((data) => {
                setApiKey(data.key);
                setNumTokens(data.requestsRemaining);
            });
        
        // get product data from stripe
        getProducts(db)
            .then((products) => {
                const sub = products.find(prod => prod.name === SUBSCRIPTION_PROD_NAME);
                const oneTime = products.find(prod => prod.name === ONE_TIME_PROD_NAME);
                setSubProduct(sub);
                setOneTimeProduct(oneTime);
            });

        // get active subscription data from stripe
        getActiveSubscription(db, auth.currentUser)
            .then((sub) => {
                setSubscription(sub);
            });
    
    }, []);

    const getCurrentUserApiKeyData = async () => {
        const docRef = doc(db, 'api-keys', auth.currentUser.uid);
        let data = { key: '', requestsRemaining: 0 };

        try {
            let docSnap = await getDoc(docRef);
            data = docSnap.data();

            // in case API key hasn't been generated yet, try again at 5s intervals
            while (!data) {
                await setTimeout(5000);
                docSnap = await getDoc(docRef);
                data = docSnap.data();
            }
        } catch (error) {
            console.error('AdminPage: Encountered error while fetching users API key:', error);
            throw error;
        }
        return data;
    };

    const signOutUser = () => {
        signOut(auth);
    };

    const getPriceString = (product) => {
        if (!product || Object.values(product).length === 0) {
            return '$0.00';
        }
        const priceInfo = product.priceInfo;
        const cents = priceInfo.unit_amount;
        
        return (cents / 100).toLocaleString('en-US', {
            style: 'currency', 
            currency: 'USD'
        });
    };

    const onPaymentSessionCreatedCallback = (error, url) => {
        if (error) {
            console.log('AdminPage: handleSubPayment: An error occured when initiating payment session.', error);
        }
        if (url) {
            window.location.assign(url);
        }
    }

    const handleOpenPortal = () => {
        openRedirectModal();
        initiateCustomerPortalSession(firebaseApp, (url) => {
            window.location.assign(url);
        });
    };

    const handleSubPayment = () => {
        openRedirectModal();
        initiatePaymentSession(db, false, auth.currentUser, subProduct, onPaymentSessionCreatedCallback);
    };
    
    const handleOneTimePayment = () => {
        openRedirectModal();
        initiatePaymentSession(db, true, auth.currentUser, oneTimeProduct, onPaymentSessionCreatedCallback)
    };

    const openRedirectModal = () => {
        setRedirecting(true);
    };

    const renderGetTokensCard = () => {
        return (
            <div className={`admin-info-card ${colors.bg_card_background} ${colors.secondary}`}>
                <p>{text.tokensIntro}</p>
                <div className='admin-tokens-buttons'>
                    <button className={`admin-tokens-button ${colors.border_accent} ${colors.accent}`}
                        onClick={handleOneTimePayment}>
                        <h1>One-Time Payment</h1>
                        <h2 className={colors.primary}>$5.00 / 100 Tokens</h2>
                        <span className={colors.secondary}>{text.tokensOneTimeDesc}</span>
                    </button>
                    <button className={`admin-tokens-button ${colors.border_accent} ${colors.accent}`}
                        onClick={handleSubPayment}>
                        <h1>Subscribe</h1>
                        <h2 className={colors.primary}>{getPriceString(subProduct)} / month</h2>
                        <span className={colors.secondary}>{text.tokensSubDesc}</span>
                    </button>
                </div>
            </div>
        );
    };

    const renderSubscriptionCard = () => {
        return (
            <div className={`admin-info-card ${colors.bg_card_background} ${colors.secondary}`}>
                {subscription.cancel_at_period_end ? <p>{text.tokensSubbedCancelled}</p> :
                    <div>
                        <p>{text.tokensSubbedIntro}</p>
                        <p>{text.tokensSubbedInfo}</p>
                    </div>}
                <button className={`admin-subscription-portal-button ${colors.accent} ${colors.border_accent}`}
                    onClick={handleOpenPortal}>
                    Manage Subscription
                </button>
            </div>
        );
    };

    return (
        <div className={`page-admin ${colors.bg_background}`}>
            <RedirectModal display={redirecting} />
            <div className='admin-content'>
                <div className='admin-top-bar'>
                    <div className={`admin-top-bar-title ${colors.primary}`}>
                        <img src={logo} alt='logo' 
                            style={{ height: 55, width: 55 }}/>
                        <h1 className={colors.accent}>{text.appName}</h1>
                        <span> | Admin</span>
                    </div>
                    <button 
                        className={`admin-top-bar-button ${colors.accent} ${colors.border_accent} ${colors.bg_background}`}
                        onClick={signOutUser}>Sign Out</button>
                </div>
                <h1 className={colors.primary}>Account Info:</h1>
                <div className={`admin-info-card ${colors.bg_card_background} ${colors.secondary}`}>
                    <ValueDisplay title={'Signed in as'} value={auth.currentUser.displayName} />
                    <ValueDisplay title={'Email'} value={auth.currentUser.email} />
                    <br/>
                    <ValueDisplay title={'API Key'} value={apiKey} displayCopy={true} />
                    <ValueDisplay title={'Tokens'} 
                        value={!subscription ? numTokens : 'unlimited'} 
                        displayWarning={!subscription ? numTokens === 0 : false} />
                </div>
                <br/>
                <h1 className={colors.primary}>{subscription ? 'Subscription:' : 'Get Tokens'}</h1>
                {subscription ? renderSubscriptionCard() : renderGetTokensCard()}
                <br/>
                <h1 className={colors.primary}>Get Started:</h1>
                <div className={`admin-info-card admin-tutorial ${colors.bg_card_background} ${colors.secondary}`}>
                    <GettingStartedTutorial />
                </div>
                <div className={`admin-footer`}>
                    <div className='admin-footer-copyright'>
                        <img src={logo} alt='logo'
                            style={{ height: 55, width: 55 }} />
                        <span className={colors.secondary}>© webtocsv.com</span>
                    </div>
                    <div className='admin-footer-email-container'>
                        <span className={colors.secondary}>Need Help?</span>
                        <a className={colors.accent} 
                            href={`mailto:${text.supportEmail}?subject=WebToCsv Support Request`}>
                                {text.supportEmail}</a>
                    </div>
                    <div className='admin-footer-email-container'>
                        <span className={colors.secondary}>Have Feedback?</span>
                        <a className={colors.accent}
                            href={`mailto:${text.feedbackEmail}?subject=WebToCsv Feedback`}>
                                {text.feedbackEmail}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default AdminPage;
